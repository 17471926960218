





























































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'LandingBlock' })
export default class LandingBlock extends Vue {
  @Prop()
  small!: boolean
}
